<template>
  <ul class="u-bare-list u-flex u-flex-wrap u-pt--s05">
    <li
      v-for="childCategory in model"
      :key="childCategory.slug"
      class="u-mb--s03 u-mr--s02"
    >
      <router-link
        v-if="categoryIsChecked(childCategory.slug)"
        :to="{ name: 'filters', query: { filters: childCategory.slug } }"
        class="c-tag active"
        :class="{'is-loading': loading}"
        @click="onRemoveCategoryClick(childCategory)"
        @keyup.enter="onRemoveCategoryClick(childCategory)"
      >
        {{ childCategory.name }}
      </router-link>
      <router-link
        v-else
        :to="{ name: 'filters', query: { filters: childCategory.slug } }"
        class="c-tag"
        :class="{'is-loading': loading}"
        @click="onAddCategoryClick(childCategory)"
        @keyup.enter="onAddCategoryClick(childCategory)"
      >
        {{ childCategory.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import searchQueryState from '@/composition/search/searchQuery'
import searchFactory from '@/composition/search/searchFactory'
import gtmUtil from '@/utils/gtm-util'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(_, context) {
    const {
      activeCategories,
      addCategory,
      removeCategory
    } = searchQueryState.searchQueryUse()

    const { loading } = searchFactory.useSearchResult()

    const categoryIsChecked = (categorySlug) => activeCategories.value.length > 0
        && activeCategories.value.some((category) => category.slug === categorySlug)

    const onAddCategoryClick = (category) => {
      context.emit('categoryClick')

      gtmUtil.selectTag(category.name)
      addCategory(category)
    }

    const onRemoveCategoryClick = (category) => {
      context.emit('categoryClick')

      removeCategory(category)
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeTag(category.name, currentTags.join(', '))
    }

    return {
      categoryIsChecked,

      onAddCategoryClick,
      onRemoveCategoryClick,
      loading
    }
  }
}
</script>

<template>
  <div class="item-filters--flat u-mb--s05">
    <div class="u-flex">
      <span
        v-for="category in model"
        :key="category.slug"
        class="item-filter-title"
        :class="{ expanded: selectedCategory ? selectedCategory.slug === category.slug: false }"
        role="button"
        tabindex="0"
        @click="selectCategory(category)"
        @keyup.enter="selectCategory(category)"
      >
        {{ category.name }}
        <Icon
          :icon="arrowIcon"
        />
      </span>
    </div>

    <div class="item-filter__list-wrap">
      <SearchFiltersList
        v-if="selectedCategory"
        :model="selectedCategory.items"
        @categoryClick="hideFilterList"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import SearchFiltersList from '@/components/search/SearchFiltersList.vue'
import arrowIcon from '@/assets/img/icons/arrow-down--thick.svg'
import Icon from '@/components/Icon.vue'

import expandCollapse from '@/mixins/expand-collapse'

export default {
  components: {
    SearchFiltersList,
    Icon
  },
  mixins: [
    expandCollapse
  ],
  props: {
    model: {
      type: Object,
      required: true
    },
    minHeight: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const selectedCategory = ref(null)

    const selectCategory = (category) => {
      if (selectedCategory.value && category.slug === selectedCategory.value.slug) {
        selectedCategory.value = null
      } else {
        selectedCategory.value = category
      }
    }

    const hideFilterList = () => {
      selectedCategory.value = null
    }

    return {
      arrowIcon,
      selectedCategory,

      selectCategory,
      hideFilterList
    }
  }
}
</script>

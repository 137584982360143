<template>
  <h3
    v-if="showHeading"
    class="c-recipe-ingredients__list__heading u-font-size-large"
  >
    {{ model.name }}
  </h3>
  <hr
    v-else
    class="c-recipe-ingredients__list__separator"
  >
  <recipe-ingredient-item
    v-for="(ingredient, index) in model.items"
    :key="ingredient.id"
    :model="ingredient"
    :class="{ 'last-row': index + 1 == model.items.length}"
  />
</template>

<script>
import { computed } from 'vue'
import RecipeIngredientItem from '@/components/recipe/RecipeIngredientItem.vue'

export default {
  components: {
    RecipeIngredientItem
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showGroupName: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    return {
      showHeading: computed(() => props.showGroupName && props.model.name)
    }
  }
}
</script>

<template>
  <div
    v-if="activeTerms.length || activeCategories.length"
    class="c-active-tags"
  >
    <button
      v-for="(term, index) in activeTerms"
      :key="index"
      class="c-tag active"
      @click="removeSearchTerm(term)"
    >
      {{ term }}
      <Icon
        :icon="iconClose"
      />
    </button>

    <button
      v-for="(category, index) in activeCategories"
      :key="index"
      class="c-tag active"
      @click="removeFilter(category)"
    >
      {{ category.name }}
      <Icon
        :icon="iconClose"
      />
    </button>

    <button
      class="c-button-link"
      @click="removeAllFilters"
    >
      {{ translations.clearAllText }}
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'
import iconClose from '@/assets/img/icons/x-mark.svg'
import searchQueryState from '@/composition/search/searchQuery'
import searchConfig from '@/composition/search/searchConfig'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Icon
  },
  setup() {
    const { searchConfiguration } = searchConfig.searchConfigurationUse()

    const {
      activeTerms,
      activeCategories,
      removeTerm,
      removeCategory,
      resetAllFilters
    } = searchQueryState.searchQueryUse()

    const removeFilter = (filter) => {
      removeCategory(filter)
      gtmUtil.removeFilter(filter.name)
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeTag(filter.name, currentTags.join(', '))
    }

    const removeSearchTerm = (term) => {
      removeTerm(term)
      gtmUtil.removeFilter(term)
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeTag(term, currentTags.join(', '))
    }

    const removeAllFilters = () => {
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeAllTags(currentTags.join(', '))
      resetAllFilters()
      gtmUtil.removeAllFilters()
    }

    return {
      iconClose,
      activeTerms,
      activeCategories,
      translations: computed(() => searchConfiguration.translations),

      removeSearchTerm,
      removeFilter,
      removeAllFilters
    }
  }
}
</script>

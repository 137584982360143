<template>
  <div>
    <Modal
      v-if="contentModel"
      ref="modal"
      :close-text="model.closeButtonLabel"
      :theme="contentModel.theme"
      :disable-body-scroll="false"
      type="info-popup"
      transparent-overlay
      @onCloseClick="onClosePopup"
    >
      <InfoPopupSurvey
        v-if="isSurvey"
        :model="contentModel"
      />
      <InfoPopupForm
        v-else
        :model="contentModel"
        @ctaClick="onCtaClick"
      />
    </Modal>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import Modal from '@/components/Modal.vue'
import InfoPopupForm from '@/components/infoPopup/InfoPopupForm.vue'
import InfoPopupSurvey from '@/components/infoPopup/InfoPopupSurvey.vue'
import useInfoPopup from '@/composition/useInfoPopup'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Modal,
    InfoPopupForm,
    InfoPopupSurvey
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const modal = ref(null)
    const {
      activationInSeconds,
      activationPercentOfPage
    } = props.model
    const {
      contentModel,
      popupId,
      hem,

      initPopup,
      setPopups,
      checkHemInUrl,
      checkDebugMode
    } = useInfoPopup()
    checkHemInUrl()
    setPopups(props.model)
    checkDebugMode()
    const isSurvey = computed(() => contentModel.value.question)
    const trackData = computed(() => {
      const isSignedUp = !!hem.value
      const {
        header: popupTitle,
        typeName: popupType
      } = contentModel.value

      return {
        popupTitle,
        popupType,
        isSignedUp
      }
    })

    const openModal = () => {
      modal.value.onOpenModal()
      gtmUtil.infoPopupOpen(trackData.value)
    }

    const onClosePopup = () => {
      gtmUtil.infoPopupClose(trackData.value)
    }

    const onCtaClick = () => {
      gtmUtil.infoPopupCtaClick(trackData.value)
      modal.value.onCloseModal()
    }

    const showPopupAfterSomeTime = () => {
      const timer = (new Date()).getTime()
      initPopup()
        .then(() => {
          if (!contentModel.value) {
            return
          }
          const timeDiff = (new Date()).getTime() - timer
          setTimeout(() => {
            openModal()
          }, Math.max(activationInSeconds * 1000 - timeDiff), 0)
        })
    }

    const showPopupAfterScrolling = () => {
      const { scrollHeight } = document.documentElement

      const threshold = scrollHeight * (activationPercentOfPage / 100)
      const onScroll = () => {
        const scrollTop = window.pageYOffset

        if (scrollTop > threshold) {
          window.removeEventListener('scroll', onScroll)
          initPopup()
            .then(() => {
              if (!contentModel.value) {
                return
              }
              openModal()
            })
        }
      }
      window.addEventListener('scroll', onScroll)
    }

    if (popupId.value) {
      onMounted(() => {
        if (activationInSeconds) {
          showPopupAfterSomeTime()
        } else if (activationPercentOfPage) {
          showPopupAfterScrolling()
        }
      })
    }

    return {
      modal,
      contentModel,
      isSurvey,

      openModal,
      onClosePopup,
      onCtaClick
    }
  }
}
</script>

<template>
  <div class="c-recipe-ingredients__list-row">
    <div class="c-recipe-ingredients__list-cell--lg u-text-capitalize-first-letter">
      <a
        v-if="model.product"
        :href="model.product.url"
      >
        <Picture
          v-if="model.product.image"
          v-bind="model.product.image"
          class="c-recipe-ingredients__thumbnail"
        />
        {{ ingredientName }}
      </a>
      <span v-else>{{ ingredientName }}</span>
    </div>
    <div class="c-recipe-ingredients__list-cell--sm">
      {{ model.name.ingredientUnitName }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import Picture from '@/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      ingredientName: computed(() => (
        props.model.secondaryUnit
          ? `${props.model.name.ingredientName} (${props.model.secondaryUnit})`
          : props.model.name.ingredientName))
    }
  }
}
</script>

export default {
  // search functionality
  startSearchTyping() {
    const gtmObj = {
      event: 'searchStartTyping'
    }
    this.pushDataLayer(gtmObj)
  },
  searchTerm(term) {
    const gtmObj = {
      event: 'searchCommence',
      searchText: term
    }
    this.pushDataLayer(gtmObj)
  },
  addFilter() {
    const gtmObj = {
      event: 'filterSelect'
    }
    this.pushDataLayer(gtmObj)
  },
  removeFilter(name) {
    const gtmObj = {
      event: 'filterRemove',
      filterText: name
    }
    this.pushDataLayer(gtmObj)
  },
  removeAllFilters() {
    const gtmObj = {
      event: 'filterClearAll'
    }
    this.pushDataLayer(gtmObj)
  },

  // menu
  expandMenu() {
    const gtmObj = {
      event: 'expandMenu'
    }
    this.pushDataLayer(gtmObj)
  },

  // recipes
  trackRecipeScalingUp(recipeName, portions, units) {
    const gtmObj = {
      recipeName,
      portions,
      units,
      event: 'portionsToggleUp'
    }
    this.pushDataLayer(gtmObj)
  },
  trackRecipeScalingDown(recipeName, portions, units) {
    const gtmObj = {
      recipeName,
      portions,
      units,
      event: 'portionsToggleDown'
    }
    this.pushDataLayer(gtmObj)
  },

  // sliders
  sliderScroll(sliderType, sliderTitle, direction) {
    const gtmObj = {
      event: 'sliderScroll',
      sliderType,
      sliderTitle,
      direction
    }
    this.pushDataLayer(gtmObj)
  },

  // video
  videoStart(heading) {
    const gtmObj = {
      event: 'videoStart',
      videoTitle: heading
    }
    this.pushDataLayer(gtmObj)
  },
  videoComplete(heading) {
    const gtmObj = {
      event: 'videoComplete',
      videoTitle: heading
    }
    this.pushDataLayer(gtmObj)
  },
  videoProgress(heading, duration) {
    const gtmObj = {
      event: 'videoProgress',
      videoPercent: `${duration}`,
      videoTitle: heading
    }
    this.pushDataLayer(gtmObj)
  },
  videoAutoplay(heading) {
    const gtmObj = {
      event: 'videoAutoplay',
      videoTitle: heading
    }
    this.pushDataLayer(gtmObj)
  },

  // forms
  formSubmit(formTitle, formContainer, hem) {
    const gtmObj = {
      event: 'formSubmit',
      formType: 'generic',
      formTitle
    }
    if (formContainer === 'salesforceformcontainerblock') {
      gtmObj.event = 'newsletterFormSubmit'
      gtmObj.formType = 'salesforce'
      gtmObj.sfmcHem = hem
    } else if (formContainer === 'contactformcontainerblock') {
      gtmObj.event = 'contactFormSubmit'
    }

    this.pushDataLayer(gtmObj)
  },

  // sorting dropdown
  pushSortingType(sortingDropdownParameter) {
    this.pushDataLayer({
      event: 'sortingToggle',
      sortingDropdownParameter
    })
  },

  selectTag(tagName) {
    this.pushDataLayer({
      event: 'selectTag',
      tagName
    })
  },

  removeTag(removedTag, currentTags) {
    this.pushDataLayer({
      event: 'removeTag',
      removedTag,
      currentTags
    })
  },

  removeAllTags(currentTags) {
    this.pushDataLayer({
      event: 'removeAllTags',
      currentTags
    })
  },

  // maps
  trackMapListItemClick(mapItemTitle, mapType) {
    this.pushDataLayer({
      event: 'mapListItemClick',
      mapItemTitle,
      mapType
    })
  },

  trackMapToggleListClick(toggleMode, mapType) {
    this.pushDataLayer({
      event: 'mapToggleListClick',
      toggleMode,
      mapType
    })
  },

  trackMapMarkerClick(mapItemTitle, mapType) {
    this.pushDataLayer({
      event: 'mapMarkerClick',
      mapItemTitle,
      mapType
    })
  },

  trackMapListArrowClick(mapType, direction) {
    this.pushDataLayer({
      event: 'mapListArrowClick',
      mapType,
      direction
    })
  },

  // dataLayer
  pushDataLayer(obj) {
    if (window.dataLayer) {
      window.dataLayer.push(obj)
    }
  },

  // helper functions
  trackClick(selector, callback) {
    Array.from(document.querySelectorAll(selector)).forEach((el) => {
      if (el) {
        el.addEventListener('click', (e) => {
          callback(el, e)
        })
      }
    })
  },

  trackLink(clickEvent, linkElement, gtmObject) {
    clickEvent.preventDefault()
    this.pushDataLayer(gtmObject)
    if (linkElement && linkElement.href) {
      setTimeout(() => {
        document.location = linkElement.href
      }, 500)
    }
  },

  trackGenericLink(selector) {
    this.trackClick(selector, (el, e) => {
      e.preventDefault()
      const gtmObject = {
        event: el.dataset.gtmTrigger
      }

      Object.entries(el.dataset).forEach(([key, value]) => {
        if (key !== 'gtmTrigger') {
          gtmObject[key] = value
        }
      })

      this.trackLink(e, el, gtmObject)
    })
  },

  // link handler for server side rendered links - initiated from main.js
  initHandler() {
    // product buy block where to buy link
    this.trackClick('.c-buy-product-block .c-buy-product__static-button', (el, e) => {
      if (el.dataset.linkType) {
        const wrapper = el.closest('.c-buy-product-block__button-wrapper')
        const title = wrapper.dataset.productTitle || ''
        const buttonType = wrapper.dataset.buttonType || ''
        this.trackLink(e, el, {
          event: el.dataset.linkType,
          buttonType,
          productName: title
        })
      }
    })

    // product page download pdf
    this.trackClick('.c-product-download .c-button', (el, e) => {
      this.trackLink(e, el, {
        event: 'productPageDownloadPdf',
        productName: el.dataset.productTitle
      })
    })

    // recipe page print
    this.trackClick('.c-recipe__print', (el, e) => {
      this.trackLink(e, el, {
        event: 'print',
        recipeName: el.dataset.recipeTitle
      })
    })

    this.trackGenericLink('[data-gtm-trigger]')
  },

  // link handler for links inside modal - initiated when modal is opened
  initModalHandler() {
    // product vendor click
    this.trackClick('.c-vendor-link', (el, e) => {
      this.trackLink(e, el, {
        event: 'whereToBuyOverlayClick',
        vendorName: el.dataset.vendorName
      })
    })

    this.trackGenericLink('.c-modal [data-gtm-trigger]')
  },

  trackBBWButtonClick({
    placement,
    productEAN,
    productName,
    categoryName,
    brandName
  }) {
    this.pushDataLayer({
      event: 'whereToBuyCta',
      placement,
      productEAN,
      productName,
      categoryName,
      brandName
    })
  },

  infoPopupOpen({
    popupTitle,
    popupType,
    isSignedUp
  }) {
    this.pushDataLayer({
      event: 'popupOpen',
      popupTitle,
      popupType,
      isSignedUp
    })
  },

  infoPopupClose({
    popupTitle,
    popupType,
    isSignedUp
  }) {
    this.pushDataLayer({
      event: 'popupClose',
      popupTitle,
      popupType,
      isSignedUp
    })
  },

  infoPopupCtaClick({
    popupTitle,
    popupType,
    isSignedUp
  }) {
    this.pushDataLayer({
      event: 'popupCtaClick',
      popupTitle,
      popupType,
      isSignedUp
    })
  },

  setHem(sfmcHem, placement) {
    this.pushDataLayer({
      event: 'setHem',
      sfmcHem,
      placement
    })
  },

  trackNavigationLink(trackingData) {
    this.pushDataLayer(trackingData)
  }
}
